import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ResourceViewDialogData } from 'utils';
import { CtxButtonComponent, CtxMarkdownRenderComponent } from 'ng-ui';

@Component({
  selector: 'ctx-view-notes',
  standalone: true,
  imports: [MatDialogModule, CtxButtonComponent, CtxMarkdownRenderComponent],
  templateUrl: './view-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewNotesComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceViewDialogData
  ) {}
}
