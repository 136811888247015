import { Component } from '@angular/core';
import { ScreenSizeService } from 'libs/ng-ui/src/lib/_services/screen-size.service';
import { AuthnService, CTX_CUSTOMER_ROUTES, CtxLayoutComponent } from 'ng-ui';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'ctx-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  standalone: true,
  imports: [CtxLayoutComponent, RouterOutlet],
})
export class DashboardLayoutComponent {
  constructor(
    private auth: AuthnService,
    public breakpoints: ScreenSizeService,
    private router: Router
  ) {}
  routes = CTX_CUSTOMER_ROUTES;

  get currentUrl() {
    return this.router.url.split(/[/]/);
  }
}
