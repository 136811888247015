import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AuthnService,
  CTX_CUSTOMER_ROUTES,
  CryptlexApiService,
  DataCacheService,
  ResourceTableService,
  ViewNotesComponent,
} from 'ng-ui';
import {
  FilterableProperties,
  ResourceName,
  SEARCH_STRINGS,
  TableActions,
  TableColumn,
  TableMenuAction,
  copyToClipboard,
  navigateWithFilters,
} from 'utils';
import { LicenseGroupFormComponent } from './license-group-form/license-group-form.component';
import { getOrganizationId } from 'customer-portal/common/utilities';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseService extends ResourceTableService {
  override resourceName: ResourceName = 'license';

  override updationComponent = LicenseGroupFormComponent;
  /** Only organisation admin has access to assign user groups */

  get editAction(): TableMenuAction[] {
    if (this.authn.jwtRole === 'organization-admin') {
      return [
        {
          icon: 'EDIT',
          label: 'Edit',
          callback: this.launchUpdationDialog.bind(this),
        },
      ];
    } else {
      return [];
    }
  }
  override get actions(): TableActions {
    return {
      create: false,
      delete: false,
      export: false,
      read: true,
      update: false,
      tag: false,
      search: false,
      selection: false,
      segments: false,
      menuActions: [
        ...this.editAction,
        {
          icon: 'COPY',
          label: 'Copy Key',
          callback: (row: { key: string }) => {
            return copyToClipboard(row.key);
          },
        },
        {
          icon: 'NAVIGATE_TO',
          label: 'View Activations',
          callback: (row: { key: string }) => {
            return navigateWithFilters(
              [
                {
                  property: 'license.key',
                  value: row.key,
                  operator: 'eq',
                },
              ],
              this.router,
              CTX_CUSTOMER_ROUTES.getNode('activations').path
            );
          },
        },
      ],
    };
  }

  override tableSearchPlaceholder: string = SEARCH_STRINGS.key;
  override tableEmptyMessage = 'No licenses found.';

  override columns: TableColumn[] = [
    { property: 'key', displayType: 'name' },
    { property: 'status', displayType: 'status' },
    { property: 'product.displayName', displayType: 'text' },
    { property: 'productVersion.displayName', displayType: 'text' },
    { property: 'expiresIn', displayType: 'licenseExpiresIn' },
    { property: 'totalActivations', displayType: 'text' },
    { property: 'allowedActivations', displayType: 'licenseActivations' },
    { property: 'deactivations', displayType: 'licenseDeactivations' },
    { property: 'metadata', displayType: 'activationMetadata' },
    { property: 'meterAttributes', displayType: 'meterAttributes' },
    {
      property: 'notes',
      displayType: 'notes',
      modalComponent: ViewNotesComponent,
    },
    { property: 'expiresAt', displayType: 'date' },
  ];

  override selections: SelectionModel<any> = new SelectionModel<any>(false, []);
  override _columnIdsToDisplay: string[] = this.allColumnIds;
  override filterableProperties: FilterableProperties = {
    key: 'objectString',
  };
  get resourceApiPath() {
    if (this.authn.jwtRole === 'organization-admin') {
      const organizationId = getOrganizationId(this.dataCacheService);
      return `/v3/organizations/${organizationId}/licenses`;
    } else {
      return '/v3/me/licenses';
    }
  }
  constructor(
    api: CryptlexApiService,
    dialog: MatDialog,
    cache: DataCacheService,
    router: Router,
    private authn: AuthnService,
    permissionsService: PermissionsService
  ) {
    super(api, dialog, cache, router, permissionsService);
  }
}
