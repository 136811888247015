import { Component, afterNextRender } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import {
  matApartment,
  matArticle,
  matBadge,
  matCloudUpload,
  matDevicesOther,
  matPerson2,
} from '@ng-icons/material-icons/baseline';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import {
  AppUpdateService,
  ScriptService,
  initializeGoogleAnalytics,
} from 'ng-ui';
@Component({
  selector: 'ctx-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
  viewProviders: [
    provideIcons({
      matArticle,
      matDevicesOther,
      matCloudUpload,
      matPerson2,
      matBadge,
      matApartment,
    }),
  ],
})
export class AppComponent {
  title = 'customer-portal';
  constructor(
    private scriptService: ScriptService,
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private appUpdateService: AppUpdateService
  ) {
    afterNextRender(() => {
      initializeGoogleAnalytics(this.scriptService, this.angulartics);
      this.appUpdateService.checkForUpdate();
    });
  }
}
