import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { marked } from 'marked';

@Component({
  selector: 'ctx-markdown-render',
  standalone: true,
  imports: [],
  templateUrl: './markdown-render.component.html',
})
export class CtxMarkdownRenderComponent implements OnInit {
  @Input() md: string;
  parsedMd: string;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.parsedMd = parseMarkdown(this.md, this.domSanitizer);
  }
}

/**
 * Returns a sanitized HTML string generated from the Markdown parameter.
 * @param md Markdown string
 */
export function parseMarkdown(md: string, domSanitizer: DomSanitizer) {
  const resultHTML = domSanitizer.sanitize(1, marked.parse(md));
  return resultHTML ? resultHTML : '';
}
