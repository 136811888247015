import { AuthnService } from '../_services/authn.service';
import { CtxRoutes, RouteNode } from './base-routes';

const _CUSTOMER_BASE_ROUTE_PATH = [
  'licenses',
  'activations',
  'users',
  'profile',
  'releases',
  'user-groups',
  'auth',
] as const;
export type _CustomerBaseRoutePaths =
  (typeof _CUSTOMER_BASE_ROUTE_PATH)[number];

export const _CUSTOMER_PAGE_ROUTE_PATHS = [
  'login',
  'reset-password',
  'forgot-password',
  'sso',
  'two-factor',
] as const;
type _CustomerPageRoutePaths = (typeof _CUSTOMER_PAGE_ROUTE_PATHS)[number];
type CustomerRouteNode = RouteNode<
  _CustomerBaseRoutePaths,
  _CustomerPageRoutePaths
>;

const isUser = (authn: AuthnService): boolean => {
  return !(authn.jwtRole === 'organization-admin');
};

class cCustomerRoutes extends CtxRoutes<
  _CustomerBaseRoutePaths,
  _CustomerPageRoutePaths
> {
  routes: CustomerRouteNode[] = [
    {
      /** icons in auth and child routes are not used anywhere */
      path: 'auth',
      label: 'Log in',
      icon: 'SIGNUP',
      hidden: () => {
        return true;
      },
      children: [
        {
          path: 'login',
          label: 'Log in',
          icon: 'SIGNUP',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'reset-password',
          label: 'Reset Password',
          icon: 'RESET_PASSWORD',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'forgot-password',
          label: 'Forgot Password',
          icon: 'RESET_PASSWORD',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'sso',
          label: 'SSO Login',
          icon: 'SSO',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'two-factor',
          label: 'Two Factor Login',
          icon: 'RESET_PASSWORD',
          hidden: () => {
            return true;
          },
        },
      ],
    },
    {
      path: 'licenses',
      label: 'Licenses',
      icon: 'LICENSES',
    },

    {
      path: 'activations',
      label: 'Activations',
      icon: 'ACTIVATIONS',
    },

    {
      path: 'releases',
      label: 'Releases',
      icon: 'RELEASES',
    },
    {
      path: 'users',
      label: 'Users',
      icon: 'USER',
      hidden: isUser,
    },
    {
      path: 'user-groups',
      label: 'User Groups',
      icon: 'ORGANIZATIONS',
      hidden: isUser,
    },
    {
      path: 'profile',
      label: 'Profile',
      icon: 'PROFILE',
    },
  ];
}

export const CTX_CUSTOMER_ROUTES = new cCustomerRoutes();
