<h2 mat-dialog-title>License</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}

<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <mat-form-field>
        <mat-label>User Groups</mat-label>
        <mat-select formControlName="userGroupIds" required multiple>
          @for (userGroup of userGroups; track userGroup) {
          <mat-option [value]="userGroup.id">
            {{ userGroup.name }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
