/* eslint-disable no-console */
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from 'utils';
import { enableProdMode } from '@angular/core';
import { appConfig } from 'customer-portal/app.config';
if (environment.get('buildType') === 'production') {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: appConfig.providers,
})
  .then(() => {
    if (
      'serviceWorker' in navigator &&
      environment.get('buildType') === 'production'
    ) {
      navigator.serviceWorker.register('/ngsw-worker.js');
    }
  })
  .catch((error) => {
    console.log(error);
  });
