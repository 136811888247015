import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
} from '@angular/router';
import {
  AppUpdateService,
  CTX_DEFAULT_MODULE_IMPORTS,
  CTX_DEFAULT_PROVIDERS,
} from 'ng-ui';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, ...CTX_DEFAULT_MODULE_IMPORTS),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({ onSameUrlNavigation: 'reload' })
    ),
    ...CTX_DEFAULT_PROVIDERS,
    AppUpdateService,
  ],
};
