import { AuthnService, DataCacheService } from 'ng-ui';

/** Returns true if currently logged in user is an organization admin */
export function isOrganizationAdmin(
  authn: AuthnService,
  cache: DataCacheService
): boolean {
  const organizationId = getOrganizationId(cache);
  return Boolean(authn.jwtRole === 'organization-admin' && organizationId);
}

/** Gets the OrganizationID from the profile of the currently logged in user */
export function getOrganizationId(cache: DataCacheService): string | undefined {
  return cache.getCachedValues('profile')?.organizationId;
}
