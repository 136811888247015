<h2 mat-dialog-title mat-dialog-title>{{ dialogData.title }}</h2>
<mat-dialog-content>
  @if (this.dialogData.property) { @if (this.dialogData.notesType==='notes') {
  <span>{{ this.dialogData.resource[this.dialogData.property] }}</span>
  }@else {
  <ctx-markdown-render
    [md]="this.dialogData.resource[this.dialogData.property]"
  ></ctx-markdown-render>
  }}
</mat-dialog-content>
<mat-dialog-actions>
  <button matDialogClose ctx-button--primary>Close</button>
</mat-dialog-actions>
