import {
  Env,
  ProjectEnvironmentVariables,
  projectKeys,
} from '../types/environment.types';

export const projectEnv = new Env<ProjectEnvironmentVariables>(
  {
    projectName: 'customer-portal',
    stripeApiKey: '',
    googleClientId: '',
    releaseServerBaseUrl: '',
    recaptchaSiteKey: '',
    posthogApiHost: '',
    posthogApiKey: '',
  },
  projectKeys
);
