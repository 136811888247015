import { Route } from '@angular/router';
import { resolveProfile } from './resolvers';
import {
  canAccessAuthRoutes,
  canAccessDashboard,
} from 'libs/ng-ui/src/lib/_services/authn.service';
import { DashboardLayoutComponent } from './common/dashboard-layout/dashboard-layout.component';
import { CTX_CUSTOMER_ROUTES } from 'ng-ui';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [canAccessDashboard],
    component: DashboardLayoutComponent,
    resolve: {
      profile: resolveProfile,
    },
    children: [
      {
        path: '',
        redirectTo: CTX_CUSTOMER_ROUTES.getNode('licenses').path,
        pathMatch: 'full',
      },
      {
        path: CTX_CUSTOMER_ROUTES.getNode('licenses').path,
        title: CTX_CUSTOMER_ROUTES.getRouteTitle(
          CTX_CUSTOMER_ROUTES.getNode('licenses').label
        ),
        loadChildren: () => {
          return import('customer-portal/pages/licenses/licenses.routes').then(
            (m) => {
              return m.routes;
            }
          );
        },
      },
      {
        path: CTX_CUSTOMER_ROUTES.getNode('activations').path,
        title: CTX_CUSTOMER_ROUTES.getRouteTitle(
          CTX_CUSTOMER_ROUTES.getNode('activations').label
        ),
        loadChildren: () => {
          return import(
            'customer-portal/pages/activations/activations.routes'
          ).then((m) => {
            return m.routes;
          });
        },
      },
      {
        path: CTX_CUSTOMER_ROUTES.getNode('releases').path,
        title: CTX_CUSTOMER_ROUTES.getRouteTitle(
          CTX_CUSTOMER_ROUTES.getNode('releases').label
        ),
        loadChildren: () => {
          return import('customer-portal/pages/releases/releases.routes').then(
            (m) => {
              return m.routes;
            }
          );
        },
      },
      {
        path: CTX_CUSTOMER_ROUTES.getNode('users').path,
        title: CTX_CUSTOMER_ROUTES.getRouteTitle(
          CTX_CUSTOMER_ROUTES.getNode('users').label
        ),
        loadChildren: () => {
          return import('customer-portal/pages/users/users.routes').then(
            (m) => {
              return m.routes;
            }
          );
        },
      },
      {
        path: CTX_CUSTOMER_ROUTES.getNode('user-groups').path,
        title: CTX_CUSTOMER_ROUTES.getRouteTitle(
          CTX_CUSTOMER_ROUTES.getNode('user-groups').label
        ),
        loadChildren: () => {
          return import(
            'customer-portal/pages/user-groups/user-groups.routes'
          ).then((m) => {
            return m.routes;
          });
        },
      },
      {
        path: CTX_CUSTOMER_ROUTES.getNode('profile').path,
        title: CTX_CUSTOMER_ROUTES.getRouteTitle(
          CTX_CUSTOMER_ROUTES.getNode('profile').label
        ),
        loadChildren: () => {
          return import('customer-portal/pages/profile/profile.routes').then(
            (m) => {
              return m.routes;
            }
          );
        },
      },
    ],
  },
  {
    canActivate: [canAccessAuthRoutes],
    title: CTX_CUSTOMER_ROUTES.getRouteTitle(
      CTX_CUSTOMER_ROUTES.getNode('auth').label
    ),
    path: CTX_CUSTOMER_ROUTES.getNode('auth').path,
    loadChildren: () => {
      return import('customer-portal/pages/auth/auth.routes').then((m) => {
        return m.routes;
      });
    },
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
