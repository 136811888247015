import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import {
  AuthnService,
  CryptlexApiService,
  resolveEntitiesToCache,
  resolveResource,
} from 'ng-ui';
import { ProfileService } from 'ng-ui';
import { LicenseService } from './pages/licenses/license.service';
import { UserGroupService } from './pages/user-groups/user-group.service';
import { inject } from '@angular/core';

/** Resolve profile for Profile page */
export function resolveProfile(
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) {
  return resolveEntitiesToCache(_route, _state, ProfileService, 'profile');
}

/** Resolve license for license page */
export function resolveLicense(
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) {
  return resolveResource(_route, _state, LicenseService);
}

/** Resolve User Groups for License Edit Form */
export function resolveUserGroups(
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) {
  const authn = inject(AuthnService);
  if (authn.jwtRole === 'organization-admin') {
    /** only admins have access to user groups */
    return resolveEntitiesToCache(
      _route,
      _state,
      UserGroupService,
      'user-group'
    );
  } else {
    return null;
  }
}

/** Resolve releases for releases*/
export const resolveReleases: ResolveFn<any> = async () => {
  const apiService = inject(CryptlexApiService);
  const esourceApiPath = '/v3/me/releases';
  try {
    const response = await apiService.getList(esourceApiPath, 1, 10, {});
    const rowCount = parseInt(response.headers.get('Pagination-Count') || '0');
    return { releases: response.body, rowCount };
  } catch (e: any) {
    return { error: e.error.message };
  }
};
