import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm, DataCacheService } from 'ng-ui';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-license-group-form',
  templateUrl: './license-group-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class LicenseGroupFormComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<LicenseGroupFormComponent>,
    private dataCacheService: DataCacheService
  ) {
    super();
  }
  /** Resolved and cached products. */
  userGroups = this.dataCacheService.getCachedValues('user-group');
  ngOnInit() {
    this.formGroup = this.fb.group({
      userGroupIds: [
        this.getInitialControlValue('userGroups', this.dialogData)?.map(
          (userGroup: any) => {
            return userGroup.id;
          }
        ),
        Validators.required,
      ],
    });
  }
}
