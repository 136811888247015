import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getOrganizationId } from 'customer-portal/common/utilities';
import {
  CryptlexApiService,
  DataCacheService,
  ResourceTableService,
} from 'ng-ui';
import {
  FilterableProperties,
  ResourceName,
  SEARCH_STRINGS,
  TableActions,
  TableColumn,
  navigateWithFilters,
} from 'utils';
import { UserGroupFormComponent } from './user-group-form/user-group-form.component';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';
@Injectable({
  providedIn: 'root',
})
export class UserGroupService extends ResourceTableService {
  override resourceName: ResourceName = 'user-group';
  // WARNING: This is set in the constructor
  get resourceApiPath() {
    const organizationId = getOrganizationId(this.dataCacheService);
    return `/v3/organizations/${organizationId}/user-groups`;
  }
  override creationComponent = UserGroupFormComponent;
  override updationComponent = UserGroupFormComponent;
  override actions: TableActions = {
    create: true,
    delete: false,
    export: false,
    read: true,
    update: false,
    tag: false,
    search: false,
    selection: false,
    segments: false,
    menuActions: [
      // There is no filtering available on the customer-portal
      {
        icon: 'NAVIGATE_TO',
        label: 'View Users',
        callback: (row) => {
          // TODO Fix route URL standardization
          return navigateWithFilters(
            [{ operator: 'eq', property: 'userGroupId', value: row?.id }],
            this.router,
            '/users'
          );
        },
      },
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
      },

      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
      },
    ],
  };

  override tableSearchPlaceholder: string = SEARCH_STRINGS.name;
  override tableEmptyMessage = 'No user groups have been created.';

  override columns: TableColumn[] = [
    { property: 'name', displayType: 'name' },
    { property: 'description', displayType: 'truncateText' },
  ];

  override selections: SelectionModel<any> = new SelectionModel<any>(false, []);
  override _columnIdsToDisplay: string[] = this.allColumnIds;
  override filterableProperties: FilterableProperties = {};

  constructor(
    api: CryptlexApiService,
    dialog: MatDialog,
    cache: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(api, dialog, cache, router, permissionsService);
  }
}
